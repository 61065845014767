import { Button, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import { ChangePassword } from "../../../store/login";
import styles from "./OTP.module.css";
import { Bounce, ToastContainer, toast } from "react-toastify";

const NewPassword = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [password, setpassword] = useState("");
  const [passwordAgain, setpasswordAgain] = useState("");
  const [error, seterror] = useState(false);

  const { loading, emailId, otpRES } = useSelector((state) => state.login);

  console.log(otpRES);

  useEffect(() => {
    if (passwordAgain != password) {
      seterror(true);
    } else if (passwordAgain === password) {
      seterror(false);
    }
  }, [passwordAgain, password]);

  //console.log(error)
  const values = {
    userId: emailId,
    password: password,
    token: otpRES.token,
  };

  const dispatch = useDispatch();

  const handelsubmit = (e) => {
    e.preventDefault();
    console.log(values);
    dispatch(ChangePassword(values)).then((result) => {
      //console.log(result)
      if (result.payload == 200) {
        console.log("changed done");
        toast.success("changed Done", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setTimeout(() => {
          navigate("/adminlogin");
        }, 1000);
      } else {
        console.log("something went wrong ");
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "20px",
      }}
    >
      <ToastContainer />
      <form
        style={{ display: "flex", flexDirection: "column", gap: "100px" }}
        onSubmit={handelsubmit}
      >
        <h1 style={{ marginBottom: "30px", fontSize: "25px" }}>
          {t("general.enter_your_new_password_please")}
        </h1>
        <div className={styles.group}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              required=""
              className={styles.input}
              onChange={(e) => setpassword(e.target.value)}
              type={showPassword ? "text" : "password"}
            ></input>
            <Button
              h="1.75rem"
              size="sm"
              color="#a0a9b4"
              background="transparent"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ?  t("general.Hide") : t("general.Show")}
            </Button>
          </div>

          <span className={styles.highlight}></span>
          <span className={styles.bar}></span>
          <label className={styles.labeltest}>{t("general.new_password")}</label>
        </div>
        <div className={styles.group}>

        <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          <input
          required=""
          type={showPassword ? "text" : "password"}
          className={styles.input}
          onChange={(e) => setpasswordAgain(e.target.value)}
        ></input>
          
        <Button
              h="1.75rem"
              size="sm"
              color="#a0a9b4"
              background="transparent"
              onClick={() => setShowPassword(!showPassword)}
            >
            {showPassword ?  t("general.Hide") : t("general.Show")}
            </Button>
          </div>
        
          <span className={styles.highlight}></span>
          <span className={styles.bar}></span>
          <label className={styles.labeltest}>{t("general.new_password")}</label>
        </div>

        {error && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ color: "red" }}>
              {t("general.password_doesnot_match")}
            </h1>
          </div>
        )}
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <button
            onClick={() => navigate("/adminlogin")}
            className={classes.back}
          >
            {t("general.Back")}
          </button>
          <button
            type="submit"
            className={classes.send}
            disabled={error || password === ""}
          >
            {t("general.Send")}
          </button>
        </Flex>
      </form>
    </div>
  );
};

export default NewPassword;
