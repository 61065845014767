import { Button, Text, SimpleGrid, Flex, Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import useDataFetcher from "../../../../sheard components/hooks/useDataFetcher";
import Loading from "../../../../sheard components/Loading";
import classes from "./index.module.css";
import { useNavigate } from "react-router-dom";

function CardDashbord() {
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const goToPatientsPage = () => {
    navigate("/Patients");
  };

  // let lang = i18n.language;
  const goToNewPatientsPage = () => {
    navigate("/Patients", {
      state: i18n.language === "en" ? { query: "new" } : { query: "جديد" },
    });
  };

  const goToContactedPatientsPage = () => {
    navigate("/Patients", {
      state:
        i18n.language === "en"
          ? { query: "communicated" }
          : { query: "تم التواصل" },
    });
  };

  let lang = i18n.language;
  const { data, loading, error } = useDataFetcher(
    `Patient/GetCardDetails?direction=${lang}`
  );

  // console.log("eslam", data);

  if (loading) {
    return (
      <Box textAlign={"center"}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return <div style={{ color: "red" }}>Error: {error.message}</div>;
  }

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 3 }} spacing={4}>
        {data.map((card) => (
          <Flex
            key={card.title}
            bg={card.background}
            justifyContent="space-between"
            padding="10px"
            borderRadius={"24px"}
            colSpan={{ base: 1, md: 2, lg: 2, xl: 3 }}
          >
            <div width="50%">
              <img
                width="150px"
                height="100%"
                src={card.image}
                alt="Caffe Latte"
                className={classes.cradimg}
              />
            </div>
            <Flex
              width="50%"
              flexDirection="column"
              justifyContent="center"
              alignItems={"center"}
              margin={"10px 0"}
            >
              <Text fontSize="50px" color="white">
                {card.numOfPatients}
              </Text>
              <Text
                pt={"0"}
                pb={"4"}
                textAlign={"center"}
                fontSize="16px"
                color="white"
              >
                {card.title}
              </Text>
              <Button
                variant="solid"
                bg="#00000040"
                colorScheme="blue"
                width={"70%"}
                onClick={() =>
                      card.title === "All Patients" ||
                      card.title === "كل المرضى"
                    ? goToPatientsPage()
                    : card.title === "New Patients" ||
                      card.title === "المرضى الجدد"
                    ? goToNewPatientsPage()
                    : goToContactedPatientsPage()
                }
              >
                {t("general.more")}
              </Button>
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </>
  );
}

export default CardDashbord;
