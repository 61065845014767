import React, { useState } from 'react'
import styles from "./OTP.module.css";
import { Flex, useStatStyles } from '@chakra-ui/react'
import classes from "./index.module.css";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckOTP } from '../../../store/login';
import Loader from "../components/Loader/Loader";

const OptPage = () => {
    const [t, i18n] = useTranslation();
    const navigate =useNavigate()
    const [OTP,setOTP]=useState("")
    const [error,setError]=useState(false)
    const {  loading,emailId } = useSelector(
        (state) => state.login
      );

      const values={
        emailId:emailId,
        OTP:OTP
      }
  
      const dispatch =useDispatch()

    const handelsubmit=(e)=>{
        e.preventDefault();
        dispatch(CheckOTP(values)).then((result)=>
        {
         console.log(result)
         if(result.payload.title==="Bad Request" || result.payload=== "Failed to fetch"){
            console.log("not correct otp")
            setError(true)
         }else if(result.payload.token )
         {
            console.log("correct otp")
            navigate("/NewPassword")
         }
        
        }
        )
        //console.log(values)
    }

    if(loading){
        return(
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
                <Loader/>
            </div>
        )
    }
  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh",padding:"20px"}}>
      <form style={{display:"flex", flexDirection:"column", gap:"25px"}} onSubmit={handelsubmit}>
   
    <h1 style={{marginBottom:"30px",fontSize:"25px"}}>
    {t("general.The_otp_was_send_to_ur_email")}
    </h1>
        
        <div className={styles.group}>
  <input required="" type="text"   className={styles.input} maxLength="6" onChange={(e)=>setOTP(e.target.value)} ></input>
  <span   className={styles.highlight}></span>
  <span  className={styles.bar}></span>
  <label className={styles.labeltest}>OTP</label>
</div>

{
    error&&(
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",}}>
            <h1 style={{color:"red"}}>
                {t("general.Wrong_otp")}
            </h1>
        </div>
    )
}
<Flex justifyContent={"space-between"} alignItems={"center"}>
<button
  className={classes.back}
  onClick={()=>navigate("..")}
>
  {t("general.Back")}
</button>
<button type="submit" className={classes.send}>
  {t("general.Send")}
</button>
</Flex>
      </form>
    </div>
  )
}

export default OptPage
