import { useState, useEffect } from 'react';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

function useDataFetcher(apiEndpoint) {
    // Define state variables to manage the fetched data and loading state
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    // Use the useEffect hook to fetch data when the component using this hook mounts
    useEffect(() => {
      // Create an asynchronous function to fetch data
      async function fetchData() {
        try {
          const response = await fetch(`${baseUrl}/${apiEndpoint}`,{
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("access-token")||sessionStorage.getItem("access-token"))}`,
              },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setData(jsonData);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      }
  
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiEndpoint]); // The hook will re-run if the apiEndpoint prop changes
  
    return { data, loading, error };
  }
  
  export default useDataFetcher;
  